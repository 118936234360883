import React from 'react'
import Sidebaar from '../../../common/components/Sidebaar/Sidebaar'
import Chair1 from '../../../assets/img/slider-details.png'
import Chair2 from '../../../assets/img/slide-deatils2.png'
import Chair3 from '../../../assets/img/slide-deatils3.png'
import UserSidebar from '../../../common/components/Sidebaar/UserSidebaar'
import { useNavigate } from 'react-router-dom'

const ProductView = () => {
    const navigate = useNavigate();
  return (
    <UserSidebar props={
        <>
        <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="shop-breadcrumb">

                            <div class="breadcrumb-main">
                                <h4 class="text-capitalize breadcrumb-title">product detatils</h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="products mb-30">
                <div class="container-fluid">
                    <div class="card product-details h-100">
                        <div class="product-item d-flex p-sm-40 p-20">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="product-item__image">
                                        <div class="wrap-gallery-article">
                                            <div id="myCarouselArticle" class="carousel slide carousel-fade" data-ride="carousel">
                                                <ol class="carousel-indicators">
                                                    <li data-target="#myCarouselArticle" data-slide-to="0" class="active"></li>
                                                    <li data-target="#myCarouselArticle" data-slide-to="1"></li>
                                                    <li data-target="#myCarouselArticle" data-slide-to="2"></li>
                                                    <li data-target="#myCarouselArticle" data-slide-to="3"></li>
                                                    <li data-target="#myCarouselArticle" data-slide-to="4"></li>
                                                    <li data-target="#myCarouselArticle" data-slide-to="5"></li>
                                                    <li data-target="#myCarouselArticle" data-slide-to="6"></li>
                                                </ol>
                                                <div class="carousel-inner" role="listbox">
                                                    <div class="carousel-item active">
                                                        <img class="img-fluid d-flex bg-opacity-primary " src={Chair1} alt="Card image cap" title="" />
                                                    </div>
                                                    <div class="carousel-item">
                                                        <img class="img-fluid d-flex bg-opacity-primary" src={Chair2} alt="Card image cap" title="" />
                                                    </div>
                                                    <div class="carousel-item">
                                                        <img class="img-fluid d-flex bg-opacity-primary" src={Chair3} alt="Card image cap" title="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="overflow-hidden " id="slider-thumbs">
                                                <ul class="reset-ul d-flex flex-wrap list-thumb-gallery">
                                                    <li>
                                                        <a href="#" class="thumbnail" data-target="#myCarouselArticle" data-slide-to="0">
                                                            <img class="img-fluid d-flex bg-opacity-primary" src={Chair1} alt="" />
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="#" class="thumbnail" data-target="#myCarouselArticle" data-slide-to="1">
                                                            <img class="img-fluid d-flex bg-opacity-primary" src={Chair2} alt="" />
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href="#" class="thumbnail" data-target="#myCarouselArticle" data-slide-to="2">
                                                            <img class="img-fluid d-flex bg-opacity-primary" src={Chair3} alt="" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class=" col-lg-7">
                                    <div class=" border-bottom mb-25 pb-sm-30 pb-15 mt-lg-0 mt-15">
                                        <div class="product-item__body">
                                            <div class="product-item__title">
                                                <h2 class="card-title fw-500">
                                                    <a href="#">This stools are also decently
                                                        stored</a>
                                                </h2>
                                            </div>
                                            <div class="product-item__content text-capitalize">
                                                <div class="stars-rating d-flex align-items-center">
                                                    <span class="star-icon las la-star active"></span>
                                                    <span class="star-icon las la-star active"></span>
                                                    <span class="star-icon las la-star active"></span>
                                                    <span class="star-icon las la-star active"></span>
                                                    <span class="star-icon las la-star-half-alt active"></span>
                                                    <span class="stars-rating__point">4.9</span>
                                                    <span class="stars-rating__review">
                                                        <span>778</span> Reviews</span>
                                                </div>
                                                <span class="product-details-brandName">Brand:<span>Louis
                                                        Poulsen</span></span>
                                                <span class="product-desc-price">
                                                    <sub>$</sub>$200.00</span>
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="product-price">$100.00</span>
                                                    <span class="product-discount">50% Off</span>
                                                </div>
                                                <p class=" product-deatils-pera">Lorem ipsum dolor sit amet, consetetur
                                                    sadipscing elitr, sed diam
                                                    nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                                                <div class="product-details__availability">
                                                    <div class="title">
                                                        <p>Available:</p>
                                                        <span class="stock">In stock</span>
                                                    </div>
                                                    <div class="title">
                                                        <p>Shipping:</p>
                                                        <span class="free"> Free</span>
                                                    </div>
                                                </div>
                                                <div class="quantity product-quantity flex-wrap">
                                                    <div class="mr-15 d-flex align-items-center flex-wrap">
                                                        <p class="fs-14 fw-500 color-dark">Quantity:</p>
                                                        <input type="button" value="-" class="qty-minus bttn bttn-left wh-36" />
                                                        <input type="number" value="1" class="qty qh-36 input" />
                                                        <input type="button" value="+" class="qty-plus bttn bttn-right wh-36" />
                                                    </div>
                                                    <span class="fs-13 fw-400 color-light my-sm-0 my-10">540 pieces available</span>
                                                </div>
                                                <div class="product-item__button mt-lg-30 mt-sm-25 mt-20 d-flex flex-wrap">
                                                    <div class=" d-flex flex-wrap product-item__action align-items-center">
                                                        <button class="btn btn-primary btn-default btn-squared border-0 mr-10 my-sm-0 my-2" onClick={() => navigate('/address')}>buy
                                                            now</button>
                                                        <button class="btn btn-secondary btn-default btn-squared border-0 px-25 my-sm-0 my-2 mr-2">
                                                            <span data-feather="shopping-bag"></span>
                                                            Add To Cart</button>
                                                        <div class="like-icon">
                                                            <button type="button">
                                                                <i class="lar la-heart icon"></i>
                                                            </button>
                                                        </div>
                                                        <div class="like-icon mr-10 my-sm-0 my-3 ">
                                                            <button type="button">
                                                                <span data-feather="share-2"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="product-deatils__social my-xl-0 my-10 d-flex align-items-center">
                                                        <ul class="d-flex">
                                                            <li>
                                                                <a href="#">
                                                                    <i class="lab la-facebook-f"></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i class="lab la-twitter"></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i class="lab la-pinterest"></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i class="lab la-linkedin-in"></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <i class="lab la-telegram"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-details__availability">
                                        <div class="title">
                                            <p>Category:</p>
                                            <span class="free">Furniture</span>
                                        </div>
                                        <div class="title">
                                            <p>Tags:</p>
                                            <span class="free"> Blue, Green, Light</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </>
    } />
  )
}

export default ProductView