import React from "react";
import Sidebaar from "../../../common/components/Sidebaar/Sidebaar";
import EditProduct from "./EditProduct";

const Product = () => {
  return (
    <Sidebaar props={
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card mt-30">
                <div className="card-body">
                  <div className="userDatatable adv-table-table global-shadow border-0 bg-white w-100 adv-table">
                    <div className="table-responsive">
                      <div className="adv-table-table__header">
                        <h4>Data Table</h4>
                      </div>
                      <br />
                      <div id="filter-form-container" />
                      <table
                        className="table mb-0 table-borderless adv-table"
                        data-sorting="true"
                        data-filter-container="#filter-form-container"
                        data-paging-current={1}
                        data-paging-position="right"
                        data-paging-size={10}
                      >
                        <thead>
                          <tr className="userDatatable-header">
                            <th>
                              <span className="userDatatable-title">id</span>
                            </th>
                            <th>
                              <span className="userDatatable-title">
                                Product Name
                              </span>
                            </th>
                            <th>
                              <span className="userDatatable-title">
                                Description
                              </span>
                            </th>
                            <th>
                              <span className="userDatatable-title">
                                Product Image
                              </span>
                            </th>
                            <th>
                              <span className="userDatatable-title">
                                Quantity
                              </span>
                            </th>
                            <th>
                              <span className="userDatatable-title">
                                Composition
                              </span>
                            </th>
                            <th>
                              <span className="userDatatable-title">
                                Presentation
                              </span>
                            </th>
                            <th>
                              <span className="userDatatable-title">Storage</span>
                            </th>
                            <th>
                              <span className="userDatatable-title">
                                Indication
                              </span>
                            </th>
                            <th data-type="html" data-name="position">
                              <span className="userDatatable-title">Price</span>
                            </th>
                            <th>
                              <span className="userDatatable-title">Size</span>
                            </th>
                            <th data-type="html" data-name="status">
                              <span className="userDatatable-title">dose</span>
                            </th>
                            <th>
                              <span className="userDatatable-title float-right">
                                Shelf Life
                              </span>
                            </th>
                            <th>
                              <span className="userDatatable-title float-right">
                                Action
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="userDatatable-content">01</div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="userDatatable-inline-title">
                                  <a href="#" className="text-dark fw-500">
                                    <h6>Kellie Marquot </h6>
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                john-keller@gmail.com
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Business Development
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Web Developer
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Web Developer
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Web Developer
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Web Developer
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Web Developer
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Web Developer
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content">
                                Web Developer
                              </div>
                            </td>

                            <td>
                              <div className="userDatatable-content">
                                January 20, 2020
                              </div>
                            </td>
                            <td>
                              <div className="userDatatable-content d-inline-block">
                                <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                  active
                                </span>
                              </div>
                            </td>
                            <td>
                              <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                <li>
                                  {/* <a href="#" className="view">
                                  <span data-feather="eye"> */}
                                  <button>view</button>
                                  {/* </span> */}
                                  {/* </a> */}
                                </li>
                                <li>
                                  <EditProduct />
                                </li>
                                <li>
                                  {/* <a href="#" className="remove"> */}
                                  <button>Delete</button>

                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } />
  );
};

export default Product;
