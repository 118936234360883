import React from "react";
import Sidebaar from "../../../common/components/Sidebaar/Sidebaar";
import Table from "../../../common/components/Table/Table";

const OrderHistory = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "user",
      headerName: "User",
      width: 150,
      editable: true,
    },
    {
      field: "oder_status",
      headerName: "Oder Status",
      width: 150,
      editable: true,
    },
    {
      field: "timestamp",
      headerName: "timestampe",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "admin",
      headerName: "Admin",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      type: "text",
      width: 110,
      editable: true,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      type: "text",
      width: 110,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      type: "text",
      width: 110,
      editable: true,
    },
    {
      field: "order status ",
      headerName: "Order Status ",
      type: "activ",
      width: 110,
      editable: true,
    },
    {
      field: "status_update",
      headerName: "Status Update ",
      type: "activ",
      width: 110,
      editable: true,
    },
  ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];
  const title = "OderHistory";

  return (
    <Sidebaar
      props={
        <div>
          <br />
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="userDatatable orderDatatable global-shadow border py-30 px-sm-30 px-20 bg-white radius-xl w-100 mb-30">
                  <Table row={rows} columns={columns} title={title} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default OrderHistory;
