import { Formik } from "formik";
import * as Yup from 'yup';
import Sidebaar from "../../../common/components/Sidebaar/Sidebaar";

const ChangePswSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
      "Please valid password. One uppercase, one lowercase, one special character and no spaces"
    )
    .required("Please Enter Your Password!"),
});

const ChangePassword = () => {
  return (
    <Sidebaar props = {
    <div className="edit-profile mt-25">
        <div className="card">
          <div className="card-header  px-sm-25 px-3">
            <div className="edit-profile__title">
              <h6>change password</h6>
              <span className="fs-13 color-light fw-400">
                Change or reset your account password
              </span>
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-lg-8 col-sm-10">
                <div className="edit-profile__body mx-lg-20">
                  <Formik
                    initialValues={{
                      password: "",
                    }}
                    validationSchema={ChangePswSchema}
                    onSubmit={(values) => {
                      // dispatch(getAuth(values));
                      console.log(values);
                    }}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <>
                        <form>
                          <div className="form-group mb-20">
                            <label htmlFor="name">Current passowrd</label>
                            <input
                              type="password"
                              className="form-control"
                              id="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            {errors.password && touched.password ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group mb-1">
                            <label htmlFor="password-field">new password</label>
                            <div className="position-relative">
                              <input
                                id="password-field"
                                type="password"
                                className="form-control pr-50"
                                name="password"
                                defaultValue="secret"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              {errors.password && touched.password ? (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "0.9em",
                                  }}
                                >
                                  {errors.password}
                                </div>
                              ) : null}
                              <span className="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2" />
                            </div>
                            <small
                              id="passwordHelpInline"
                              className="text-light fs-13"
                            >
                              Minimum 6 characters
                            </small>
                          </div>
                          <div className="form-group mb-1">
                            <label htmlFor="password-field1">
                              Confirm Password
                            </label>
                            <div className="position-relative">
                              <input
                                id="password-field1"
                                type="password"
                                className="form-control pr-50"
                                name="password"
                                defaultValue="secret"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              {errors.password && touched.password ? (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "0.9em",
                                  }}
                                >
                                  {errors.password}
                                </div>
                              ) : null}
                              <span className="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2" />
                            </div>
                            <small
                              id="passwordHelpInline"
                              className="text-light fs-13"
                            >
                              Minimum 6 characters
                            </small>
                          </div>
                          <div className="button-group d-flex flex-wrap pt-45 mb-35">
                            <button className="btn btn-primary btn-default btn-squared mr-15 text-capitalize">
                              Save Changes
                            </button>
                            <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                              cancel
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>} />
  );
};

export default ChangePassword;