import signupTop from "../../styles/img/svg/signupTop.svg";
import signupBottom from "../../styles/img/svg/signupBottom.svg";
import signupIllustration from "../../styles/img/svg/signupIllustration.svg";
import google from "../../styles/img/svg/google.svg";
import facebook from "../../styles/img/svg/facebook.svg";
import twitter from "../../styles/img/svg/twitter.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const VerifyOTPSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Please Enter Your Email!"),
});

const VerifyEmail = () => {
  return (
    <div className="signUP-admin">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-5 p-0">
            <div className="signUP-admin-left signIn-admin-left position-relative">
              <div className="signUP-overlay">
                <img className="svg signupTop" src={signupTop} alt="img" />
                <img
                  className="svg signupBottom"
                  src={signupBottom}
                  alt="img"
                />
              </div>
              {/* <!-- End: .signUP-overlay  --> */}
              <div className="signUP-admin-left__content">
                <div className="text-capitalize mb-md-30 mb-15 d-flex align-items-center justify-content-md-start justify-content-center">
                  <a
                    className="wh-36 bg-primary text-white radius-md mr-10 content-center"
                    href="index.html"
                  >
                    a
                  </a>
                  <span className="text-dark">admin</span>
                </div>
                <h1>Bootstrap 4 React Web Application</h1>
              </div>
              {/* <!-- End: .signUP-admin-left__content  --> */}
              <div className="signUP-admin-left__img">
                <img
                  className="img-fluid svg"
                  src={signupIllustration}
                  alt="img"
                />
              </div>
              {/* <!-- End: .signUP-admin-left__img  --> */}
            </div>
            {/* <!-- End: .signUP-admin-left  --> */}
          </div>
          {/* <!-- End: .col-xl-4  --> */}
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8">
            <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
              <div className="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
              </div>
              {/* <!-- End: .signUp-topbar  --> */}
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-12">
                  <div className="edit-profile mt-md-25 mt-0">
                    <div className="card border-0">
                      <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                        <div className="edit-profile__title">
                          <h6>
                            Verify Your
                            <span className="color-primary">Email</span>
                          </h6>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="edit-profile__body">
                          <Formik
                            initialValues={{
                              email: ""
                            }}
                            validationSchema={VerifyOTPSchema}
                            onSubmit={(values) => {
                              console.log(values);
                            }}
                          >
                            {({
                              values,
                              touched,
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                            }) => (
                              <>
                                <form onSubmit={handleSubmit}>
                                  <div className="form-group mb-20">
                                    <label htmlFor="email">Email Address</label>
                                    <input
                                      required
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      placeholder="Enter Email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    {errors.email && touched.email ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {errors.email}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div class="button-group d-flex pt-3 mb-20 justify-content-between flex-wrap">
                                    <Link
                                      to="/login"
                                      class="btn btn-light btn-default btn-squared fw-400 text-capitalize m-1"
                                    >
                                      <i class="las la-arrow-left mr-10"></i>
                                      Back to Sign-In
                                    </Link>
                                    <Link
                                      to="/VerifyOTP"
                                      class="btn text-white btn-primary btn-default btn-squared text-capitalize m-1"
                                    >
                                      Submit
                                    </Link>
                                  </div>
                                </form>
                              </>
                            )}
                          </Formik>
                        </div>
                      </div>
                      {/* <!-- End: .card-body --> */}
                    </div>
                    {/* <!-- End: .card --> */}
                  </div>
                  {/* <!-- End: .edit-profile --> */}
                </div>
                {/* <!-- End: .col-xl-5 --> */}
              </div>
            </div>
            {/* <!-- End: .signUp-admin-right  --> */}
          </div>
          {/* <!-- End: .col-xl-8  --> */}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
