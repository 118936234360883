import React from 'react'

const Card = (props) => {
    return (
        <div className="card" style={{ width: '18rem' }}>
            <img className="card-img-top" src="..." alt="Card image cap" />
            <div className="card-body">
                <h5 className="card-title">{props.title}</h5>
                <p className="card-text">{props.details}</p>
                <a className="btn btn-primary" onClick={props.clickEvent}>{props.btn}</a>
            </div>
        </div>
    )
}

export default Card