import signupTop from "../../styles/img/svg/signupTop.svg";
import signupBottom from "../../styles/img/svg/signupBottom.svg";
import signupIllustration from "../../styles/img/svg/signupIllustration.svg";
import google from "../../styles/img/svg/google.svg";
import facebook from "../../styles/img/svg/facebook.svg";
import twitter from "../../styles/img/svg/twitter.svg";
import { Formik } from "formik";
import * as Yup from "yup";

const SigInSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
      "Please valid password. One uppercase, one lowercase, one special character and no spaces"
    )
    .required("Please Enter Your Password!"),
  confirm_password: Yup.string()
    .required("Please Enter Confirm Password")
    .test("password-match", "Password musth match", function (value) {
      return this.parent.password === value;
    }),
});

const ForgetPassword = () => {
  return (
    <div className="signUP-admin">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-5 p-0">
            <div className="signUP-admin-left signIn-admin-left position-relative">
              <div className="signUP-overlay">
                <img className="svg signupTop" src={signupTop} alt="img" />
                <img
                  className="svg signupBottom"
                  src={signupBottom}
                  alt="img"
                />
              </div>
              {/* <!-- End: .signUP-overlay  --> */}
              <div className="signUP-admin-left__content">
                <div className="text-capitalize mb-md-30 mb-15 d-flex align-items-center justify-content-md-start justify-content-center">
                  <a
                    className="wh-36 bg-primary text-white radius-md mr-10 content-center"
                    href="index.html"
                  >
                    a
                  </a>
                  <span className="text-dark">admin</span>
                </div>
                <h1>Bootstrap 4 React Web Application</h1>
              </div>
              {/* <!-- End: .signUP-admin-left__content  --> */}
              <div className="signUP-admin-left__img">
                <img
                  className="img-fluid svg"
                  src={signupIllustration}
                  alt="img"
                />
              </div>
              {/* <!-- End: .signUP-admin-left__img  --> */}
            </div>
            {/* <!-- End: .signUP-admin-left  --> */}
          </div>
          {/* <!-- End: .col-xl-4  --> */}
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8">
            <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
              <div className="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
              </div>
              {/* <!-- End: .signUp-topbar  --> */}
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-12">
                  <div className="edit-profile mt-md-25 mt-0">
                    <div className="card border-0">
                      <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                        <div className="edit-profile__title">
                        <h6>
                            Update Your
                            <span className="color-primary">Password</span>
                          </h6>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="edit-profile__body">
                          <Formik
                            initialValues={{
                              password: "",
                              confirm_password: "",
                            }}
                            validationSchema={SigInSchema}
                            onSubmit={(values) => {
                              console.log(values);
                            }}
                          >
                            {({
                              values,
                              touched,
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                            }) => (
                              <>
                                <form onSubmit={handleSubmit}>
                                  <div className="form-group mb-20">
                                    <label htmlFor="password">
                                      Password
                                    </label>
                                    <div className="position-relative">
                                    <input
                                      required
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder="Enter Password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />
                                      <div className="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2"></div>
                                      </div>
                                    {errors.password && touched.password ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {errors.password}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="form-group mb-15">
                                    <label htmlFor="password-field">
                                     Confirm password
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        required
                                        id="password-field"
                                        type="password"
                                        className="form-control"
                                        name="confirm_password"
                                        placeholder="Enter confirm Password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2"></div>
                                    </div>
                                    {errors.confirm_password &&
                                    touched.confirm_password ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {errors.confirm_password}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="signUp-condition signIn-condition">
                                    <div className="checkbox-theme-default custom-checkbox ">
                                      <input
                                        className="checkbox"
                                        type="checkbox"
                                        id="check-1"
                                      />
                                    </div>
                                  </div>
                                  <div className="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                    <button className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn ">
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </>
                            )}
                          </Formik>
                        </div>
                      </div>
                      {/* <!-- End: .card-body --> */}
                    </div>
                    {/* <!-- End: .card --> */}
                  </div>
                  {/* <!-- End: .edit-profile --> */}
                </div>
                {/* <!-- End: .col-xl-5 --> */}
              </div>
            </div>
            {/* <!-- End: .signUp-admin-right  --> */}
          </div>
          {/* <!-- End: .col-xl-8  --> */}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
