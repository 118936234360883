import "./profile.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import Sidebaar from "../../../common/components/Sidebaar/Sidebaar";

const ViewProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Please Enter First Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  last_name: Yup.string()
    .required("Please Enter Last Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Please Enter Your Email!"),
  mobile_no: Yup.string()
    .required("Please Enter Mobile Number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(10, "Must be exactly 10  digits")
    .max(10, "Must be exactly 10 digits"),
  gender: Yup.string()
    .required("Selecting the gender field is required")
    .oneOf(["male", "female", "other"]),

  dob: Yup.date()
    .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    .required("Please Enter Your Birth Date"),

  address: Yup.string().required("Please enter your address"),
  city: Yup.string().required("Please enter your city"),
  state: Yup.string().required("Please enter your state"),
  zipCode: Yup.string()
    .required("Please Enter Your Zipcode")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});
const ViewProfile = () => {
  return (
    <Sidebaar props = {
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "",
        gender: "",
        dob: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
      }}
      validationSchema={ViewProfileSchema}
      onSubmit={(values) => {
        // dispatch(getAuth(values));
        console.log(values);
      }}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <>
          <form>
            <div className="container rounded bg-white mt-5 mb-5">
              <div className="row">
                <div className="col-md-3 border-right">
                  <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                    {/*  eslint-disable-next-line jsx-a11y/alt-text */}
                    <img
                      className="rounded-circle mt-5"
                      width="150px"
                      src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                    />
                    <span className="font-weight-bold">Edogaru</span>
                    <span className="text-black-50">edogaru@mail.com.my</span>
                    <span> </span>
                  </div>
                </div>
                <div className="col-md-9 border-right">
                  <div className="p-3 py-5">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4 className="text-right">Profile Settings</h4>
                    </div>
                    <div className="row mt-8">
                      <div className="col-md-6">
                        <label className="labels">First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          placeholder="first name"
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                        />
                        {errors.first_name && touched.first_name ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.first_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <label className="labels">Last Name</label>
                        <input
                          name="last_name"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                        />
                        {errors.last_name && touched.last_name ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.last_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">Email ID</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="enter email id"
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">Mobile Number</label>
                        <input
                          name="mobile_no"
                          type="text"
                          className="form-control"
                          placeholder="enter phone number"
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile_no}
                        />
                        {errors.mobile_no && touched.mobile_no ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.mobile_no}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-6">
                        <label className="labels">Gender</label>
                        <br />
                        <div className="form-check form-check-inline mb-0 me-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="femaleGender"
                            value="option1"
                            disabled
                          />
                          <label
                            className="form-check-label"
                            htmlFor="femaleGender"
                          >
                            Female
                          </label>
                        </div>

                        <div className="form-check form-check-inline mb-0 me-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="maleGender"
                            value="option2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="maleGender"
                          >
                            Male
                          </label>
                        </div>

                        <div className="form-check form-check-inline mb-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="otherGender"
                            value="option3"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="otherGender"
                          >
                            Other
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label className="labels">DOB</label>
                        <input
                          name="dob"
                          type="date"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dob}
                          disabled
                        />
                        {errors.dob && touched.dob ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.dob}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-12">
                        <label className="labels">Address</label>
                        <input
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="enter address "
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          disabled
                        />
                        {errors.address && touched.address ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <label className="labels">City</label>
                        <input
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="enter City"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                        />
                        {errors.city && touched.city ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.city}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mt-8">
                      <div className="col-md-6">
                        <label className="labels">State</label>
                        <input
                          name="state"
                          type="text"
                          className="form-control"
                          placeholder="enter State"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                        />
                        {errors.state && touched.state ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.state}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <label className="labels">ZipCode</label>
                        <input
                          name="zipCode"
                          type="text"
                          className="form-control"
                          placeholder="ZipCode"
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zipCode}
                        />
                        {errors.zipCode && touched.zipCode ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.zipCode}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="mt-5 text-center">
                      <button
                        className="btn btn-primary profile-button"
                        type="button"
                      >
                        Save Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </Formik>} />
  );
};
export default ViewProfile;
