import RoutesConfig from './features/routes/RouteConfig';

function App() {

  return (
   <RoutesConfig/>
  );
}

export default App;



// import React, { useState, useEffect, Suspense, ReactFragment } from 'react';
// import { Route, BrowserRouter, Routes } from 'react-router-dom';
// import { routesConfig } from 'common/constants/routeConstants';
// import { useToasts,ToastProvider } from 'react-toast-notifications';

// const RenderTosterProvider = () => {
//   return (
//     <BrowserRouter basename='/'>
//       <Routes>
//         {routesConfig.map((props:any, index:any) => (
//           <Route {...props} key={index} />
//         ))}
//       </Routes>
//     </BrowserRouter>
//   );
// };

// function App() {
//   const [views, setViews] = useState<ReactFragment>([]);

//   const renderVA:any = () => (
//     <div>
//           <ToastProvider>
//             <RenderTosterProvider />
//           </ToastProvider>
//     </div>
//   );
//     useEffect(()=>{
//       setViews(renderVA);
//     },[])

//   return (
//     <Suspense fallback='Loading Local Files...'>
//       <div>{views}</div>
//     </Suspense>
//   );
// }

// export default App;
