import React from 'react'
import { DataGrid } from '@mui/x-data-grid';

export default function Table(props) {

    return (
        <div style={{ height: 400, width: '100%' }}>
          <br />
          <h3>{props.title}</h3>
          <br />
          <DataGrid
            rows={props.row}
            columns={props.columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 15]}
          />
        </div>
      );
}