import signupTop from "../../styles/img/svg/signupTop.svg";
import signupBottom from "../../styles/img/svg/signupBottom.svg";
import signupIllustration from "../../styles/img/svg/signupIllustration.svg";
import google from "../../styles/img/svg/google.svg";
import facebook from "../../styles/img/svg/facebook.svg";
import twitter from "../../styles/img/svg/twitter.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { getAuth } from "../../redux/store/slice";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const SigInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Please Enter Your Email!"),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
      "Please valid password. One uppercase, one lowercase, one special character and no spaces"
    )
    .required("Please Enter Your Password!"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(
    (state) => state?.mediComReducer?.auth?.result?.token
  );
  console.log(token);
  const { setAuth } = useAuth;
  useEffect(() => {
    // if (token) {
    // }
    // setAuth({token})
  }, [token]);

  return (
    <div className="signUP-admin">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-5 p-0">
            <div className="signUP-admin-left signIn-admin-left position-relative">
              <div className="signUP-overlay">
                <img className="svg signupTop" src={signupTop} alt="img" />
                <img
                  className="svg signupBottom"
                  src={signupBottom}
                  alt="img"
                />
              </div>
              {/* <!-- End: .signUP-overlay  --> */}
              <div className="signUP-admin-left__content">
                <div className="text-capitalize mb-md-30 mb-15 d-flex align-items-center justify-content-md-start justify-content-center">
                  <a
                    className="wh-36 bg-primary text-white radius-md mr-10 content-center"
                    href="index.html"
                  >
                    a
                  </a>
                  <span className="text-dark">admin</span>
                </div>
                <h1>Bootstrap 4 React Web Application</h1>
              </div>
              {/* <!-- End: .signUP-admin-left__content  --> */}
              <div className="signUP-admin-left__img">
                <img
                  className="img-fluid svg"
                  src={signupIllustration}
                  alt="img"
                />
              </div>
              {/* <!-- End: .signUP-admin-left__img  --> */}
            </div>
            {/* <!-- End: .signUP-admin-left  --> */}
          </div>
          {/* <!-- End: .col-xl-4  --> */}
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8">
            <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
              <div className="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
                <p className="mb-0">
                  Don't have an account?
                  <Link to="/register" className="color-primary">
                    Sign up
                  </Link>
                </p>
              </div>
              {/* <!-- End: .signUp-topbar  --> */}
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-12">
                  <div className="edit-profile mt-md-25 mt-0">
                    <div className="card border-0">
                      <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                        <div className="edit-profile__title">
                          <h6>
                            Sign up to{" "}
                            <span className="color-primary">Admin</span>
                          </h6>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="edit-profile__body">
                          <Formik
                            initialValues={{
                              email: "",
                              password: "",
                            }}
                            validationSchema={SigInSchema}
                            onSubmit={(values) => {
                              // dispatch(getAuth(values));
                              navigate("/");
                              console.log(values);
                            }}
                          >
                            {({
                              values,
                              touched,
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                            }) => (
                              <>
                                <form onSubmit={handleSubmit}>
                                  <div className="form-group mb-20">
                                    <label htmlFor="email">Email Address</label>
                                    <input
                                      required
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      placeholder="Email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    {errors.email && touched.email ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {errors.email}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="form-group mb-15">
                                    <label htmlFor="password-field">
                                      password
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        required
                                        id="password-field"
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <div className="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2"></div>
                                    </div>
                                    {errors.password && touched.password ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {errors.password}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="signUp-condition signIn-condition">
                                    <div className="checkbox-theme-default custom-checkbox ">
                                      <input
                                        className="checkbox"
                                        type="checkbox"
                                        id="check-1"
                                      />
                                      <label htmlFor="check-1">
                                        <span className="checkbox-text">
                                          Keep me logged in
                                        </span>
                                      </label>
                                    </div>
                                    <a href="verifyEmail">
                                      forget password
                                    </a>
                                  </div>
                                  <div className="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                    {/* <button className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn "> */}
                                      <Link to='/'>sign in</Link>
                                    {/* </button> */}
                                  </div>
                                  <p className="social-connector text-center mb-sm-25 mb-15  mt-sm-30 mt-20">
                                    <span>Or</span>
                                  </p>
                                  <div className="button-group d-flex align-items-center justify-content-md-start justify-content-center">
                                    <ul className="signUp-socialBtn">
                                      <li>
                                        <button className="btn text-dark px-30">
                                          <img
                                            className="svg"
                                            src={google}
                                            alt="img"
                                          />{" "}
                                          Sign up with Google
                                        </button>
                                      </li>
                                      <li>
                                        <button className=" radius-md wh-48 content-center">
                                          <img
                                            className="svg"
                                            src={facebook}
                                            alt="img"
                                          />
                                        </button>
                                      </li>
                                      <li>
                                        <button className="radius-md wh-48 content-center">
                                          <img
                                            className="svg"
                                            src={twitter}
                                            alt="img"
                                          />
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </form>
                              </>
                            )}
                          </Formik>
                        </div>
                      </div>
                      {/* <!-- End: .card-body --> */}
                    </div>
                    {/* <!-- End: .card --> */}
                  </div>
                  {/* <!-- End: .edit-profile --> */}
                </div>
                {/* <!-- End: .col-xl-5 --> */}
              </div>
            </div>
            {/* <!-- End: .signUp-admin-right  --> */}
          </div>
          {/* <!-- End: .col-xl-8  --> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
