import { Routes, Route } from "react-router-dom";
import Register from "../components/Register";
import LoginPage from "../components/Login/LoginPage";
import Layout from "../components/Layout";
import Missing from "../components/Missing";
import Unauthorized from "../components/Unauthorized";
import RequireAuth from "../components/RequireAuth";
import Dashboard from "../components/Dashboard/Dashboard";
import Success from "../components/Success/Success";
import VerifyEmail from "../components/ForgotPassword/VerifyEmail";
import VerifyOTP from "../components/ForgotPassword/VerifyOTP";
import ForgetPassword from "../components/ForgotPassword/ForgetPassword";
import BillAddress from "../components/BillAddress/BillAddress";
import Cart from "../components/Cart/Cart";
import Category from "../components/Category/Category";
import AddCategory from "../components/Category/AddCategory";
import EditCategory from "../components/Category/EditCategory";
import CheckOut from "../components/CheckOut/CheckOut";
import OrderHistory from "../components/OrderHistory/OrderHistory";
import Product from "../components/Product/Product";
import AddProduct from "../components/Product/AddProduct";
import EditProduct from "../components/Product/EditProduct";
import Wishlist from "../components/Wishlist/Wishlist";
import RegisterPage from "../components/Register/RegisterPage";
import SecondPage from "../components/Register/SecondPage";
import ThirdPage from "../components/Register/ThirdPage";
import ViewProfile from "../components/Profile/ViewProfile";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import ProductPage from "../components/UserDashboard/ProductPage";
import ProductView from "../components/UserDashboard/ProductView";
import Report from "../components/Reports/Report";
import Side from "../../common/components/side/Side";

const ROLES = {
  User: 2001,
  Admin: 5150,
};

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}

        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="register2" element={<SecondPage />} />
        <Route path="register3" element={<ThirdPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="success" element={<Success />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="verifyEmail" element={<VerifyEmail />} />
        <Route path="verifyOTP" element={<VerifyOTP />} />
        <Route path="forgotPassword" element={<ForgetPassword />} />
        <Route path="address" element={<BillAddress />} />
        <Route path="cart" element={<Cart />} />
        <Route path="category" element={<Category />} />
        <Route path="addCategory" element={<AddCategory />} />
        <Route path="editCategory" element={<EditCategory />} />
        <Route path="checkout" element={<CheckOut />} />
        <Route path="orderHistory" element={<OrderHistory />} />
        <Route path="product" element={<Product />} />
        <Route path="addProduct" element={<AddProduct />} />
        <Route path="editProduct" element={<EditProduct />} />
        <Route path="wishlist" element={<Wishlist />} />
        <Route path="profile" element={<ViewProfile />} />
        <Route path="changePassword" element={<ChangePassword />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="user" element={<ProductPage />} />
        <Route path="productView" element={<ProductView />} />
        <Route path="report" element={<Report />} />
        <Route path="side" element={<Side />} />

        {/* we want to protect these routes */}

        {/* <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="/" element={<Dashboard />} />
        </Route> */}

        {/* catch all */}

        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
};

export default RoutesConfig;
