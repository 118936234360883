import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './features/context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store} from './features/redux/store/configureStore';

import "./features/styles/css/bootstrap/bootstrap.css";
import "../src/features/styles/css/daterangepicker.css";
import "../src/features/styles/css/fontawesome.css";
import "../src/features/styles/css/footable.standalone.min.css";
import "../src/features/styles/css/fullcalendar@5.2.0.css";
import "../src/features/styles/css/jquery-jvectormap-2.0.5.css";
import "../src/features/styles/css/jquery.mCustomScrollbar.min.css";
import "../src/features/styles/css/leaflet.css";
import "../src/features/styles/css/line-awesome.min.css";
import "../src/features/styles/css/magnific-popup.css";
import "../src/features/styles/css/MarkerCluster.css";
import "../src/features/styles/css/MarkerCluster.Default.css";
import "../src/features/styles/css/select2.min.css";
import "../src/features/styles/css/slick.css";
import "../src/features/styles/css/star-rating-svg.css";
import "../src/features/styles/css/trumbowyg.min.css";
import "../src/features/styles/css/wickedpicker.min.css";
import "./style.scss"


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('shop')
);














// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import App from './App';
// import { store } from 'features/redux/configureStore';
// import './index.scss';
// import { API } from 'common/api/generalService';
// import { getCookie } from 'common/utils/cookieUtils';
// import jwt_decode from "jwt-decode";
// import { CommonConstants } from 'common/constants/commonConstants';
// import { createRoot } from 'react-dom/client';


// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//   }
// }

// Intercept APIs to add Bearer Token
// const BEARER_TOKEN = getCookie('access_token');

// let decodedToken:any = jwt_decode(BEARER_TOKEN);

// if (decodedToken && decodedToken.role) {
//   localStorage.setItem(CommonConstants.Role, decodedToken.role)
//   if (decodedToken.role !== CommonConstants.Admin) {
//     localStorage.setItem(CommonConstants.AccessRights, decodedToken.access);
//   }
// }

// For GET requests
// API.interceptors.request.use(
//   req => {
//     // Add configurations here
//     if (req && req.headers) {
//       req.headers['Authorization'] = 'Bearer ' + BEARER_TOKEN;
//     }
//     return req;
//   },
//   err => Promise.reject(err)
// );

// For POST requests
// API.interceptors.response.use(
//   res => {
//     // Add configurations here
//     if (res.status === 201) {
//       console.log('Posted Successfully');
//     }
//     return res;
//   },
//   err => Promise.reject(err)
// );

//Rendering App wrapped 
// around by provider 
// connected to redux 
// store.
// const container:any = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(
//   <React.StrictMode>
//   <Provider store={store}>
//     <App />
//   </Provider>
// </React.StrictMode>
// );