import { createSlice } from '@reduxjs/toolkit';
import initialState from './state';
const slice = createSlice({
    name : 'shopState',
    initialState,
    reducers : {
        getAuth: (state,action) => {
            return {
                ...state,
                action,
            };
        },
        setAuth: (state,action) => {
            state.auth = action.payload;
        }
    }
})

export const {
getAuth,
setAuth,
} = slice.actions;

export default slice.reducer