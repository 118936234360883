import { Formik } from "formik";
import * as Yup from "yup";

const CategorySchema = Yup.object().shape({
  category_name: Yup.string()
    .required("Please Enter Category Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  image: Yup.string()
    .required("Please Enter Image")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
});
const EditCategory = () => {
  return (
    <div className="container">
      <button type="button" data-toggle="modal" data-target="#myModal">
        Edit
      </button>
      {/* The Modal */}
      <div className="modal" id="myModal">
        <div className="modal-dialog">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title">Edit Category</h4>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="add-product__body px-sm-40 px-20">
                <Formik
                  initialValues={{
                    category_name: "",
                    image: "",
                  }}
                  validationSchema={CategorySchema}
                  onSubmit={(values) => {
                    // dispatch(getAuth(values));
                    console.log(values);
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form>
                      <div className="form-group">
                        <label htmlFor="name1">Category Name</label>
                        <input
                          name="category_name"
                          type="text"
                          className="form-control"
                          id="name1"
                          placeholder="Enter Category Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.category_name}
                        />
                        {errors.category_name && touched.category_name ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.category_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="name2">Category Image</label>
                        <input
                          name="image"
                          type="file"
                          className="form-control"
                          id="name2"
                          placeholder="Enter Image"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.image}
                        />
                        {errors.image && touched.image ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {errors.image}
                          </div>
                        ) : null}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary">
                Save Category
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
