import React from 'react'
import Sidebaar from '../../../common/components/Sidebaar/Sidebaar'

const Success = () => {
    return (
        <Sidebaar props = {<div className="container-fluid">
            <div className=" global-shadow wizard6 checkout-review border py-30 px-sm-50 px-30 bg-white radius-xl w-100 mb-30">
                <div className="row justify-content-center">
                    <div className="cus-8 col-12">
                        <div className="card payment-status bg-normal p-sm-30 p-15">
                            <div className="card-body bg-white bg-shadow radius-xl px-sm-30 py-sm-25 m-0 px-15 py-1">
                                <div className="payment-status__area  py-sm-25 py-20 text-center">
                                    <div className="content-center">
                                        <span className="wh-34 bg-success rounded-circle content-center">
                                            <span className="las la-check fs-16 color-white"></span></span>
                                    </div>
                                    <h4 className="fw-500 mt-20 mb-10">Payment Successful</h4>
                                    <span className="fs-15 color-gray">Thank you! We've received your payment.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>} />
    )
}

export default Success