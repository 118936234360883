import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getAuthorize } from '../../services/services';
import { getAuth, setAuth } from '../store/slice'

export function* getAuthentication(action) {
    try{
        const response = yield call(getAuthorize, action.payload);
        yield put(setAuth(response))
    } catch (error) {
        return;
    }
}

export default function* saga() {
    yield all([
        yield takeEvery(getAuth, getAuthentication),
    ]);
}