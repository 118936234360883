import React from "react";
import "./Side.scss";
import Bars from "../../../assets/img/svg/bars.svg";
import Logo_Dark from "../../../assets/img/logo_dark.png";
import Logo_Light from "../../../assets/img/logo_white.png";
import { Link } from "react-router-dom";
import Auth from "../../../assets/img/author-nav.jpg";

const Side = (props) => {
  return (
    <>
      <div class="wrapper">
        <nav id="sidebar">
          <div id="dismiss">
            <i class="fas fa-arrow-left"></i>
          </div>

          {/* <div class="sidebar-header">
            <h3>Bootstrap Sidebar</h3>
          </div> */}
          <div className="sidebar sidebar-collapse" id="sidebar">
            <div className="sidebar__menu-group">
              <ul className="sidebar_nav">
                <li className="menu-title">
                  <span>Main menu</span>
                </li>
                <li className="has-child open">
                  <a href="/" className="active">
                    <span data-feather="home" className="nav-icon"></span>
                    <span className="menu-text">Dashboard</span>
                  </a>
                  <ul>
                    <li>
                      <a className="" href="category">
                        Category
                      </a>
                    </li>
                    <li>
                      <a className="" href="product">
                        Product
                      </a>
                    </li>
                    <li>
                      <a className="" href="report">
                        Reports
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div id="content">
          <header className="header-top">
            <nav class="navbar navbar-light">
              <div className="navbar-left">
                <button
                  type="button"
                  id="sidebarCollapse"
                  className="navbar-left sidebar-toggle"
                  style={{ border: "none" }}
                >
                  <img className="svg" src={Bars} alt="img" />
                </button>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="navbar-brand" href="#">
                  <img className="dark" src={Logo_Dark} alt="svg" />
                  <img className="light" src={Logo_Light} alt="img" />
                </a>
                <form action="/" className="search-form">
                  <span data-feather="search"></span>
                  <input
                    className="form-control mr-sm-2 box-shadow-none"
                    type="text"
                    placeholder="Search..."
                  />
                </form>
              </div>
              <div className="navbar-right">
                <ul className="navbar-right__menu">
                  <li className="nav-search d-none">
                    <a href="#" className="search-toggle">
                      <i className="la la-search"></i>
                      <i className="la la-times"></i>
                    </a>
                    <form action="/" className="search-form-topMenu">
                      <span
                        className="search-icon"
                        data-feather="search"
                      ></span>
                      <input
                        className="form-control mr-sm-2 box-shadow-none"
                        type="text"
                        placeholder="Search..."
                      />
                    </form>
                  </li>
                  <li className="nav-author">
                    <div className="dropdown-custom">
                      <a href="javascript:;" className="nav-item-toggle">
                        <img src={Auth} alt="" className="rounded-circle" />
                      </a>
                      <div className="dropdown-wrapper">
                        <div className="nav-author__info">
                          <div className="author-img">
                            <img src={Auth} alt="" className="rounded-circle" />
                          </div>
                          <div>
                            <h6>Abdullah Bin Talha</h6>
                            <span>UI Designer</span>
                          </div>
                        </div>
                        <div className="nav-author__options">
                          <ul>
                            <li>
                              <Link to="/profile">
                                <span data-feather="user"></span> Profile
                              </Link>
                            </li>
                            <li>
                              <a href="address">
                                <span data-feather="key"></span> Billing
                              </a>
                            </li>
                            <li>
                              <a href="changePassword">
                                <span data-feather="bell"></span> Change
                                Password
                              </a>
                            </li>
                          </ul>
                          <a href="login" className="nav-author__signout">
                            <span data-feather="log-out"></span> Sign Out
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
        </div>
        {/* <main className="main-content">
          <aside className="sidebar-wrapper">
            <div className="sidebar sidebar-collapse" id="sidebar">
              <div className="sidebar__menu-group">
                <ul className="sidebar_nav">
                  <li className="menu-title">
                    <span>Main menu</span>
                  </li>
                  <li className="has-child open">
                    <a href="/" className="active">
                      <span data-feather="home" className="nav-icon"></span>
                      <span className="menu-text">Dashboard</span>
                    </a>
                    <ul>
                      <li>
                        <a className="" href="category">
                          Category
                        </a>
                      </li>
                      <li>
                        <a className="" href="product">
                          Product
                        </a>
                      </li>
                      <li>
                        <a className="" href="report">
                          Reports
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </aside> */}

        {/* <div className="contents"> */}
        <div class="atbd-page-content mt-4">{props.props}</div>
      </div>
      <footer className="footer-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <p>
                  2020 @<a href="#">Aazztech</a>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-menu text-right">
                <ul>
                  <li>
                    <a href="#">About</a>
                  </li>
                  <li>
                    <a href="#">Team</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* </main> */}
      <div id="overlayer">
        <span className="loader-overlay">
          <div className="atbd-spin-dots spin-lg">
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
          </div>
        </span>
      </div>
      <div className="overlay-dark-sidebar"></div>
      <div class="overlay"></div>
      {/* </div> */}
    </>
  );
};

export default Side;
