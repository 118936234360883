import createSagaMiddleware from '@redux-saga/core';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { sagas } from '../sagas/index';
import slice from './slice';

const sagaMiddleware = createSagaMiddleware();

export const reducer = combineReducers({
  mediComReducer: slice
});

export const store = configureStore({
  reducer,
  middleware: [sagaMiddleware]
});
sagaMiddleware.run(sagas);