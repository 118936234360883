import React from "react";
import { Link } from "react-router-dom";
import progress3 from "../../styles/img/svg/progress3.svg";
import { Formik } from "formik";
import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
      "Please valid password. One uppercase, one lowercase, one special character and no spaces"
    )
    .required("Please Enter Your Password!"),
  gender: Yup.string()
    .required("Selecting the gender field is required")
    .oneOf(["male", "female", "other"]),

  dob: Yup.date()
    .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    .required("Please Enter Your Birth Date"),

  address: Yup.string().required("Please enter your address"),
});
const SecondPage = () => {
  return (
    <div class="container-fluid">
      <div class=" checkout wizard8 global-shadow border px-sm-50 px-20 mb-30 bg-white radius-xl w-100">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <div class="row justify-content-center">
              <div class="col-xl-5">
                <div class="checkout-progress-indicator">
                  <div class="checkout-progress justify-content-center px-0">
                    <div class="step completed" id="1">
                      <span class="las la-check"></span>
                    </div>
                    <div class="step current" id="2">
                      <span>2</span>
                    </div>
                    <div class="step" id="3">
                      <span>3</span>
                    </div>
                  </div>
                  <div class="card-header border-bottom-0">
                    <h4>Please fill in your address</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the dummy typesetting
                      industry.
                    </p>
                    <img class="svg" src={progress3} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-7 col-lg-8 col-sm-10">
                <div class="card checkout-shipping-form">
                  <div class="card-header border-bottom-0 pb-sm-0 pb-1 px-0">
                    <h4 class="fw-500">2. Please fill in your address</h4>
                  </div>
                  <div class="card-body p-0">
                    <div class="edit-profile__body">
                      <Formik
                        initialValues={{
                          password: "",
                          gender: "",
                          dob: "",
                        }}
                        validationSchema={SignUpSchema}
                        onSubmit={(values) => {
                          // dispatch(getAuth(values));
                          console.log(values);
                        }}
                      >
                        {({
                          values,
                          touched,
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <>
                            <form>
                              <div class="form-group create-passord">
                                <label for="phoneNumber">Password</label>
                                <input
                                  name="password"
                                  type="password"
                                  class="form-control"
                                  id="phoneNumber"
                                  placeholder="Password"
                                  value="121445"
                                />
                                <span class="create-passord-warn">
                                  Enter a valid password. Min 6 characters long
                                </span>
                              </div>
                              <div class="form-group create-passord">
                                <label for="passConf">Confirm Password</label>
                                <input
                                  name="password"
                                  type="password"
                                  class="form-control"
                                  id="passConf"
                                  placeholder="Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                {errors.password && touched.password ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.password}
                                  </div>
                                ) : null}
                                <span class="create-passord-warn">
                                  Enter a valid password. Min 6 characters long
                                </span>
                              </div>

                              <div class="form-group">
                                <label for="ender">Gender</label>
                                <br />
                                <div class="form-check form-check-inline mb-0 me-4">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="femaleGender"
                                    value="option1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="femaleGender"
                                  >
                                    Female
                                  </label>
                                </div>

                                <div class="form-check form-check-inline mb-0 me-4">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="maleGender"
                                    value="option2"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="maleGender"
                                  >
                                    Male
                                  </label>
                                </div>

                                <div class="form-check form-check-inline mb-0">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="otherGender"
                                    value="option3"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="otherGender"
                                  >
                                    Other
                                  </label>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="dob">DOB</label>
                                <input
                                  name="dob"
                                  type="date"
                                  class="form-control"
                                  id="dob"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.dob}
                                />
                                {errors.dob && touched.dob ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.dob}
                                  </div>
                                ) : null}
                              </div>

                              <div class="button-group d-flex pt-3 mb-20 justify-content-between flex-wrap">
                                <Link
                                  to="/register"
                                  class="btn btn-light btn-default btn-squared fw-400 text-capitalize m-1"
                                >
                                  <i class="las la-arrow-left mr-10"></i>
                                  Previous
                                </Link>
                                <Link
                                  to="/register3"
                                  class="btn text-white btn-primary btn-default btn-squared text-capitalize m-1"
                                >
                                  Save & Next
                                  <i class="ml-10 mr-0 las la-arrow-right"></i>
                                </Link>
                              </div>
                            </form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondPage;
