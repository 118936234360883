import React from "react";
import Side from "../../../common/components/side/Side";
import Sidebaar from "../../../common/components/Sidebaar/Sidebaar";

const Dashboard = () => {
  return (
    <Side
      props={
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-12">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">Dashboard</h4>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6 col-ssm-12 mb-30">
              <div className="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between">
                <div>
                  <div className="overview-content">
                    <h1>7,461</h1>
                    <p>Orders</p>
                    <div className="ap-po-details-time">
                      <span className="color-success">
                        <i className="las la-arrow-up"></i>
                        <strong>25%</strong>
                      </span>
                      <small>Since last week</small>
                    </div>
                  </div>
                </div>
                <div className="ap-po-timeChart">
                  <div className="overview-single__chart d-md-flex align-items-end">
                    <div className="parentContainer">
                      <div>
                        <canvas id="mychart8"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6 col-ssm-12 mb-30">
              <div className="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between">
                <div>
                  <div className="overview-content">
                    <h1>$28,947</h1>
                    <p>Revenue</p>
                    <div className="ap-po-details-time">
                      <span className="color-success">
                        <i className="las la-arrow-up"></i>
                        <strong>25%</strong>
                      </span>
                      <small>Since last week</small>
                    </div>
                  </div>
                </div>
                <div className="ap-po-timeChart">
                  <div className="overview-single__chart d-md-flex align-items-end">
                    <div className="parentContainer">
                      <div>
                        <canvas id="mychart9"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6 col-ssm-12 mb-30">
              <div className="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between">
                <div>
                  <div className="overview-content">
                    <h1>$3,241</h1>
                    <p>Avg. Order Value</p>
                    <div className="ap-po-details-time">
                      <span className="color-danger">
                        <i className="las la-arrow-down"></i>
                        <strong>25%</strong>
                      </span>
                      <small>Since last week</small>
                    </div>
                  </div>
                </div>
                <div className="ap-po-timeChart">
                  <div className="overview-single__chart d-md-flex align-items-end">
                    <div className="parentContainer">
                      <div>
                        <canvas id="mychart10"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6 col-ssm-12 mb-30">
              <div className="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between">
                <div>
                  <div className="overview-content">
                    <h1>45.32k</h1>
                    <p>Unique visitors</p>
                    <div className="ap-po-details-time">
                      <span className="color-success">
                        <i className="las la-arrow-up"></i>
                        <strong>35%</strong>
                      </span>
                      <small>Since last week</small>
                    </div>
                  </div>
                </div>
                <div className="ap-po-timeChart">
                  <div className="overview-single__chart d-md-flex align-items-end">
                    <div className="parentContainer">
                      <div>
                        <canvas id="mychart11"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 mb-30">
              <div className="card broder-0">
                <div className="card-header">
                  <h6>Total Revenue</h6>
                  <div className="card-extra">
                    <ul
                      className="card-tab-links mr-3 nav-tabs nav"
                      role="tablist"
                    >
                      <li>
                        <a
                          href="#tl_revenue-week"
                          data-toggle="tab"
                          id="tl_revenue-week-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Week
                        </a>
                      </li>
                      <li>
                        <a
                          href="#tl_revenue-month"
                          data-toggle="tab"
                          id="tl_revenue-month-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Month
                        </a>
                      </li>
                      <li>
                        <a
                          className="active"
                          href="#tl_revenue-year"
                          data-toggle="tab"
                          id="tl_revenue-year-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          Year
                        </a>
                      </li>
                    </ul>
                    <div className="dropdown dropleft">
                      {/* <a href="#" role="button" id="revenue1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                      <span data-feather="more-horizontal"></span>
                      {/* </a> */}
                      <div className="dropdown-menu" aria-labelledby="revenue1">
                        {/* <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <a className="dropdown-item" href="#">Something else here</a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade"
                      id="tl_revenue-week"
                      role="tabpanel"
                      aria-labelledby="tl_revenue-week-tab"
                    >
                      <div className="revenue-labels">
                        <div>
                          <strong className="text-primary">$72,848</strong>
                          <span>Current Period</span>
                        </div>
                        <div>
                          <strong>$52,458</strong>
                          <span>Previous Period</span>
                        </div>
                      </div>
                      <div className="wp-chart">
                        <div className="parentContainer">
                          <div>
                            <canvas id="myChart6W"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tl_revenue-month"
                      role="tabpanel"
                      aria-labelledby="tl_revenue-month-tab"
                    >
                      <div className="revenue-labels">
                        <div>
                          <strong className="text-primary">$72,848</strong>
                          <span>Current Period</span>
                        </div>
                        <div>
                          <strong>$52,458</strong>
                          <span>Previous Period</span>
                        </div>
                      </div>
                      <div className="wp-chart">
                        <div className="parentContainer">
                          <div>
                            <canvas id="myChart6M"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="tl_revenue-year"
                      role="tabpanel"
                      aria-labelledby="tl_revenue-year-tab"
                    >
                      <div className="revenue-labels">
                        <div>
                          <strong className="text-primary">$72,848</strong>
                          <span>Current Period</span>
                        </div>
                        <div>
                          <strong>$52,458</strong>
                          <span>Previous Period</span>
                        </div>
                      </div>
                      <div className="wp-chart">
                        <div className="parentContainer">
                          <div>
                            <canvas id="myChart6"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Dashboard;
