import "./product.scss";
import { Formik } from "formik";
import * as Yup from "yup";

const ProductSchema = Yup.object().shape({
  product_name: Yup.string()
    .required("Please Enter Product Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  product_image: Yup.string()
    .required("Please Enter Image")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  description: Yup.string()
    .required("Please Enter Description Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  quantity: Yup.string()
    .required("Please Enter Quantity")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  composition: Yup.string()
    .required("Please Enter Composition")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  presentation: Yup.string()
    .required("Please Enter Presentation")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  storage: Yup.string()
    .required("Please Enter Storage")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  indication: Yup.string()
    .required("Please Enter Indication")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  price: Yup.string()
    .required("Please Enter Price")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  dose: Yup.string()
    .required("Please Enter Dose")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  shelf_life: Yup.string()
    .required("Please Enter Self Life")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  size: Yup.string()
    .required("Please Enter Size")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
});

const AddProduct = () => {
  return (
    <>
      {/* <div className="contents"> */}
      {/* <div className="container-fluid"> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="shop-breadcrumb">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Add product</h4>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="global-shadow border px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-10">
                    <div className="mx-sm-30 mx-20 ">
                      <div className="card add-product p-sm-30 p-20 mb-30">
                        <div className="card-body p-0">
                          <div className="card-header">
                            <h6 className="fw-500">Add Product</h6>
                          </div>
                          <div className="add-product__body px-sm-40 px-20">
                            <Formik
                              initialValues={{
                                product_name: "",
                                description: "",
                                product_image: "",
                                quantity: "",
                                composition: "",
                                presentation: "",
                                storage: "",
                                indication: "",
                                price: "",
                                size: "",
                                dose: "",
                                shelf_life: "",
                              }}
                              validationSchema={ProductSchema}
                              onSubmit={(values) => {
                                // dispatch(getAuth(values));
                                console.log(values);
                              }}
                            >
                              {({
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                              }) => (
                                <form>
                                  <div className="col-md-24 ">
                                    <div className="p-4 py-5">
                                      <div className="row mt-8">
                                        <div className="col-md-6">
                                          <label className="labels">
                                            Product Name
                                          </label>
                                          <input
                                            type="text"
                                            name="product_name"
                                            className="form-control"
                                            placeholder="Enter Product Name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.product_name}
                                          />
                                          {errors.product_name &&
                                          touched.product_name ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.product_name}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6">
                                          <label className="labels">
                                            Description
                                          </label>
                                          <input
                                            name="description"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                          />
                                          {errors.description &&
                                          touched.description ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.description}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <label className="labels">Image</label>
                                        <input
                                          name="product_image"
                                          type="file"
                                          className="form-control"
                                          placeholder="Enter Description"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.product_image}
                                        />
                                        {errors.product_image &&
                                        touched.product_image ? (
                                          <div
                                            style={{
                                              color: "red",
                                              fontSize: "0.9em",
                                            }}
                                          >
                                            {errors.product_image}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="row mt-8">
                                        <div className="col-md-6">
                                          <label className="labels">
                                            Composition
                                          </label>
                                          <input
                                            type="text"
                                            name="composition"
                                            className="form-control"
                                            placeholder="Enter Composition"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.composition}
                                          />
                                          {errors.composition &&
                                          touched.composition ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.composition}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6">
                                          <label className="labels">
                                            Presentation
                                          </label>
                                          <input
                                            name="presentation"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Presentation"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.presentation}
                                          />
                                          {errors.presentation &&
                                          touched.presentation ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.presentation}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>

                                      <div className="row mt-8">
                                        <div className="col-md-6">
                                          <label className="labels">
                                            Storage
                                          </label>
                                          <input
                                            type="text"
                                            name="storage"
                                            className="form-control"
                                            placeholder="Enter Storage"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.storage}
                                          />
                                          {errors.storage && touched.storage ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.storage}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6">
                                          <label className="labels">
                                            Indication
                                          </label>
                                          <input
                                            name="indication"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Indication"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.indication}
                                          />
                                          {errors.indication &&
                                          touched.indication ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.indication}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="row mt-8">
                                        <div className="col-md-6">
                                          <label className="labels">Dose</label>
                                          <input
                                            type="text"
                                            name="dose"
                                            className="form-control"
                                            placeholder="Enter Storage"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.dose}
                                          />
                                          {errors.dose && touched.dose ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.dose}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6">
                                          <label className="labels">
                                            Shelf Life
                                          </label>
                                          <input
                                            name="shelf_life"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Shelf Life"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.shelf_life}
                                          />
                                          {errors.shelf_life &&
                                          touched.shelf_life ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.shelf_life}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>

                                      <div className="row mt-10">
                                        <div className="col-md-4">
                                          <label className="labels">
                                            Quantity
                                          </label>
                                          <input
                                            type="number"
                                            name="quantity"
                                            className="form-control"
                                            placeholder="Enter Quantity"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.quantity}
                                          />
                                          {errors.quantity &&
                                          touched.quantity ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.quantity}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-4">
                                          <label className="labels">
                                            Price
                                          </label>
                                          <input
                                            name="price"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Price"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.price}
                                          />
                                          {errors.price && touched.price ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.price}
                                            </div>
                                          ) : null}
                                        </div>

                                        <div className="col-md-4">
                                          <label className="labels">Size</label>
                                          <input
                                            name="size"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter size"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.size}
                                          />
                                          {errors.size && touched.size ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {errors.size}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                        <div className="button-group add-product-btn d-flex justify-content-end mt-40">
                          <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                            cancel
                          </button>
                          <button className="btn btn-primary btn-default btn-squared text-capitalize">
                            save product
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
