import { API } from "../../common/api/generalService";
import {
  GET_AUTH,
} from "../api/apiEndpoints";

const url = "http://localhost:8080";

// < --------------- USER --------------- >

export const getAuthorize = async (id) => {
  try {
    const response = await API.post(`${url}/${GET_AUTH}`, id);
    return response.data;
  } catch (e) {
    return {};
  }
};