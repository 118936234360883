import { useEffect } from "react"
import { Outlet } from "react-router-dom"

const Layout = () => {

    useEffect(()=>{
        window.addEventListener('load', function () {
            this.setTimeout(()=>{
                    document.querySelector('body').classList.add("loaded")        
                    let ele = document.getElementById("overlayer");
                    ele.style.display('none');
            },2000)
            });
                },[])
    return (
        <>
        <main className="main-content">
            <Outlet />
        </main>
        <div id="overlayer">
        <span className="loader-overlay">
            <div className="atbd-spin-dots spin-lg">
                <span className="spin-dot badge-dot dot-primary"></span>
                <span className="spin-dot badge-dot dot-primary"></span>
                <span className="spin-dot badge-dot dot-primary"></span>
                <span className="spin-dot badge-dot dot-primary"></span>
            </div>
        </span>
    </div>
    </>
    )
}

export default Layout
