import React from "react";
import Sidebaar from "../../../common/components/Sidebaar/Sidebaar";
import "./BillAddress.scss";
import { Formik } from "formik";
import * as Yup from "yup";

const BillAddreshSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please Enter First Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  mobile_no: Yup.string()
    .required("Please Enter Mobile Number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(10, "Must be exactly 10  digits")
    .max(10, "Must be exactly 10 digits"),
  alternative_no: Yup.string()
    .required("Please Enter Mobile Number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(10, "Must be exactly 10  digits")
    .max(10, "Must be exactly 10 digits"),
  address_line1: Yup.string().required("Please enter your address"),
  address_line2: Yup.string().required("Please enter your address"),
  city: Yup.string().required("Please enter your city"),
  state: Yup.string().required("Please enter your state"),
  zipCode: Yup.string()
    .required("Please Enter Your Zipcode")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
  place: Yup.string().required("Please enter your state"),
});
const ViewProfile = () => {
  return (
    <Sidebaar
      props={
        <Formik
          initialValues={{
            name: "",
            mobile_no: "",
            alternative_no: "",
            address_line1: "",
            address_line2: "",
            city: "",
            state: "",
            zipCode: "",
            place: "",
          }}
          validationSchema={BillAddreshSchema}
          onSubmit={(values) => {
            // dispatch(getAuth(values));
            console.log(values);
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <form>
                <div className="container rounded bg-white mt-5 mb-5">
                  <div className="row">
                    <div className="col-md-16 ">
                      <div className="p-5 py-5">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h4 className="text-right">Bill Address</h4>
                        </div>
                        <div className="row mt-8">
                          <div className="col-md-12">
                            <label className="labels">Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Enter Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            {errors.name && touched.name ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label className="labels">Mobile No</label>
                            <input
                              name="mobile_no"
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobile_no}
                            />
                            {errors.mobile_no && touched.mobile_no ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.mobile_no}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label className="labels">Alternative No</label>
                            <input
                              name="alternative_no"
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.alternative_no}
                            />
                            {errors.alternative_no && touched.alternative_no ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.alternative_no}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <label className="labels">Address Line1</label>
                            <input
                              type="text"
                              name="address_line1"
                              className="form-control"
                              placeholder="enter email id"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address_line1}
                            />
                            {errors.address_line1 && touched.address_line1 ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.address_line1}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <label className="labels">Address Line2</label>
                            <input
                              name="address_line2"
                              type="text"
                              className="form-control"
                              placeholder="enter phone number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address_line2}
                            />
                            {errors.address_line2 && touched.address_line2 ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.address_line2}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <label className="labels">City</label>
                            <input
                              name="city"
                              type="text"
                              className="form-control"
                              placeholder="enter City"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                            />
                            {errors.city && touched.city ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.city}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <label className="labels">State</label>
                            <input
                              name="state"
                              type="text"
                              className="form-control"
                              placeholder="enter State"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.state}
                            />
                            {errors.state && touched.state ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.state}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-md-6">
                            <label className="labels">ZipCode</label>
                            <input
                              name="zipCode"
                              type="text"
                              className="form-control"
                              placeholder="ZipCode"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.zipCode}
                            />
                            {errors.zipCode && touched.zipCode ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.zipCode}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label className="labels">Place</label>
                            <select
                              name="place"
                              class="form-control form-control-lg"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.place}
                            >
                              <option value="1">Office</option>
                              <option value="2">Home</option>
                            </select>
                            {errors.place && touched.place ? (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "0.9em",
                                }}
                              >
                                {errors.place}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-5 text-center">
                          <button
                            className="btn btn-primary profile-button"
                            type="button"
                          >
                            Save Address
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      }
    />
  );
};
export default ViewProfile;
