import React from 'react'
import Card from '../../../common/components/Card/Card'
import UserSidebar from '../../../common/components/Sidebaar/UserSidebaar'

const Wishlist = () => {
  const handleSubmit = () => {
    console.log("Working Good");
    
  }
  const a = [1, 2, 3]
  return (
    <UserSidebar props={
      a.map((any) => 
        <Card title={"Creator"} details={"ABCDEFGHIJKLMNOPQRSTUVWXYZ"} btn={"Wishlist"} clickEvent={handleSubmit} />
      )
    } />
  )
}

export default Wishlist