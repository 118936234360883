import progress1 from "../../styles/img/svg/progress1.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { getAuth } from "../../redux/store/slice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const SignUpSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Please Enter First Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  last_name: Yup.string()
    .required("Please Enter Last Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Please Enter Your Email!"),
  mobile_no: Yup.string()
    .required("Please Enter Mobile Number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(10, "Must be exactly 10  digits")
    .max(10, "Must be exactly 10 digits"),
  // password: Yup.string()
  //   .matches(
  //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
  //     "Please valid password. One uppercase, one lowercase, one special character and no spaces"
  //   )
  //   .required("Please Enter Your Password!"),
});

const RegisterPage = () => {
  const dispatch = useDispatch();
  return (
    <div class="container-fluid">
      <div class=" checkout wizard8 global-shadow border px-sm-50 px-20 mb-30 bg-white radius-xl w-100">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <div class="row justify-content-center">
              <div class="col-xl-5">
                <div class="checkout-progress-indicator ">
                  <div class="checkout-progress justify-content-center px-0">
                    <div class="step current" id="1">
                      <span>1</span>
                    </div>
                    <div class="step" id="2">
                      <span>2</span>
                    </div>
                    <div class="step" id="3">
                      <span>3</span>
                    </div>
                  </div>
                  <div class="card-header border-bottom-0">
                    <h4>Create Account</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the dummy typesetting
                      industry.
                    </p>
                    <div class="div">
                      <img class="svg w-100" src={progress1} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-7 col-lg-8 col-sm-10">
                <div class="card checkout-shipping-form h-100vh">
                  <div class="card-header border-bottom-0 align-content-start pb-sm-0 pb-1 px-0">
                    <h4 class="fw-500">1. Please create your account</h4>
                  </div>
                  <div class="card-body p-0">
                    <div class="edit-profile__body">
                      <Formik
                        initialValues={{
                          first_name: "",
                          last_name: "",
                          email: "",
                          mobile_no: "",
                        }}
                        validationSchema={SignUpSchema}
                        onSubmit={(values) => {
                          // dispatch(getAuth(values));
                          console.log(values);
                        }}
                      >
                        {({
                          values,
                          touched,
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <>
                            <form>
                              <div class="form-group">
                                <label for="name1">First Name</label>
                                <input
                                  name="first_name"
                                  type="text"
                                  class="form-control"
                                  id="name1"
                                  placeholder="First Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.first_name}
                                />
                                {errors.first_name && touched.first_name ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.first_name}
                                  </div>
                                ) : null}
                              </div>
                              <div class="form-group">
                                <label for="name2">Last Name</label>
                                <input
                                  name="last_name"
                                  type="text"
                                  class="form-control"
                                  id="name2"
                                  placeholder="Last Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.last_name}
                                />
                                {errors.last_name && touched.last_name ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.last_name}
                                  </div>
                                ) : null}
                              </div>
                              <div class="form-group">
                                <label for="name2">Email Address</label>
                                <input
                                  name="email"
                                  type="email"
                                  class="form-control"
                                  id="name2"
                                  placeholder="username@email.com"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />
                                {errors.email && touched.email ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.email}
                                  </div>
                                ) : null}
                              </div>
                              <div class="form-group">
                                <label for="phoneNumber">phone number</label>
                                <input
                                  name="mobile_no"
                                  type="tel"
                                  class="form-control"
                                  id="phoneNumber"
                                  placeholder="+440"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.mobile_no}
                                />
                                {errors.mobile_no && touched.mobile_no ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.mobile_no}
                                  </div>
                                ) : null}
                              </div>

                              <div class="button-group d-flex pt-20 mb-20 justify-content-md-end justify-content-center">
                                <Link
                                  to="/register2"
                                  class="btn btn-primary btn-default btn-squared text-capitalize text-white"
                                >
                                  Save & Next
                                  <i class="ml-10 mr-0 las la-arrow-right"></i>
                                </Link>
                              </div>
                            </form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
