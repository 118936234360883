import React from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Sidebaar from '../../../common/components/Sidebaar/Sidebaar'

const SignUpSchema = Yup.object().shape({
    startDate: Yup.date()
        .required("Start Date is Required"),

    endDate: Yup.date()
        .required("End Date is Required"),

    gender: Yup.string()
        .required("Selecting the report is required")
        .oneOf(["admin", "user"]),
});

const Report = () => {
    return (
        <Sidebaar props={
            <>
                <div className="edit-profile mt-25">
                    <div className="card">
                        <div className="card-header  px-sm-25 px-3">
                            <div className="edit-profile__title">
                                <h6>Report Ganarate</h6>
                                <span className="fs-13 color-light fw-400">
                                    Create report of admin or user
                                </span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-lg-8 col-sm-10">
                                    <div className="edit-profile__body mx-lg-20">
                                        <Formik
                                            initialValues={{
                                                startDate: "",
                                                endDate: "",
                                            }}
                                            validationSchema={SignUpSchema}
                                            onSubmit={(values) => {
                                                // dispatch(getAuth(values));
                                                console.log(values);
                                            }}
                                        >
                                            {({
                                                values,
                                                touched,
                                                errors,
                                                handleBlur,
                                                handleChange,
                                                handleSubmit,
                                            }) => (
                                                <>
                                                    <form>
                                                        <div class="form-group">
                                                            <label for="startDate">From Date</label>
                                                            <input
                                                                name="startDate"
                                                                type="date"
                                                                class="form-control"
                                                                id="startDate"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.startDate}
                                                            />
                                                            {errors.startDate && touched.startDate ? (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "0.9em",
                                                                    }}
                                                                >
                                                                    {errors.startDate}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="endDate">To Date</label>
                                                            <input
                                                                name="endDate"
                                                                type="date"
                                                                class="form-control"
                                                                id="endDate"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.endDate}
                                                            />
                                                            {errors.endDate && touched.endDate ? (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "0.9em",
                                                                    }}
                                                                >
                                                                    {errors.endDate}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="ender">Report</label>
                                                            <br />
                                                            <div class="form-check form-check-inline mb-0 me-4">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    name="inlineRadioOptions"
                                                                    id="admin"
                                                                    value="option1"
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="admin"
                                                                >
                                                                    Admin
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline mb-0 me-4">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    name="inlineRadioOptions"
                                                                    id="user"
                                                                    value="option2"
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="user"
                                                                >
                                                                    User
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class="button-group d-flex pt-3 mb-20 justify-content-between flex-wrap">
                                                            <Link
                                                                to=""
                                                                class="btn text-white btn-primary btn-default btn-squared text-capitalize m-1"
                                                            >
                                                                Genarate
                                                            </Link>
                                                        </div>
                                                    </form>
                                                </>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        } />
    )
}

export default Report