import React from "react";
import Bars from "../../../assets/img/svg/bars.svg";
import Logo_Dark from "../../../assets/img/logo_dark.png";
import Logo_Light from "../../../assets/img/logo_white.png";
import Auth from "../../../assets/img/author-nav.jpg";
import { Link } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import CategoryIcon from '@mui/icons-material/Category';
import Inventory2Icon from '@mui/icons-material/Inventory2';

const Sidebaar = (props) => {
  return (
    <>
      <div className="mobile-search">
        <form className="search-form">
          <span data-feather="search"></span>
          <input
            className="form-control mr-sm-2 box-shadow-none"
            type="text"
            placeholder="Search..."
          />
        </form>
      </div>
      <div className="mobile-author-actions"></div>
      <header className="header-top">
        <nav
          className="navbar navbar-light"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <div className="navbar-left">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" className="sidebar-toggle">
              <img className="svg" src={Bars} alt="img" />
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="navbar-brand" href="#">
              <img className="dark" src={Logo_Dark} alt="svg" />
              <img className="light" src={Logo_Light} alt="img" />
            </a>
            <form action="/" className="search-form">
              <span data-feather="search"></span>
              <input
                className="form-control mr-sm-2 box-shadow-none"
                type="text"
                placeholder="Search..."
              />
            </form>
            <div className="top-menu">
              <div className="strikingDash-top-menu position-relative">
                <ul>
                  <li>
                    <a href="/" className="active">
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a className="" href="category">
                      <CategoryIcon />Category
                    </a>
                  </li>
                  <li>
                    <a className="" href="product">
                      <Inventory2Icon />Products
                    </a>
                  </li>
                  <li>
                    <a className="" href="report">
                      <AssessmentIcon />Reports
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="navbar-right">
            <ul className="navbar-right__menu">
              <li className="nav-search d-none">
                <a href="#" className="search-toggle">
                  <i className="la la-search"></i>
                  <i className="la la-times"></i>
                </a>
                <form action="/" className="search-form-topMenu">
                  <span className="search-icon" data-feather="search"></span>
                  <input
                    className="form-control mr-sm-2 box-shadow-none"
                    type="text"
                    placeholder="Search..."
                  />
                </form>
              </li>
              {/* <li className="nav-message">
                <div className="dropdown-custom">
                  <a href="javascript:;" className="nav-item-toggle">
                    <span data-feather="mail"></span>
                  </a>
                  <div className="dropdown-wrapper">
                    <h2 className="dropdown-wrapper__title">
                      Messages{" "}
                      <span className="badge-circle badge-success ml-1">2</span>
                    </h2>
                    <ul>
                      <li className="author-online has-new-message">
                        <div className="user-avater">
                          <img src={Team_1} alt="" />
                        </div>
                        <div className="user-message">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              Web Design
                            </a>
                            <span className="time-posted">3 hrs ago</span>
                          </p>
                          <p>
                            <span
                              className="desc text-truncate"
                              style={{ maxWidth: 215 }}
                            >
                              Lorem ipsum dolor amet cosec Lorem ipsum
                            </span>
                            <span className="msg-count badge-circle badge-success badge-sm">
                              1
                            </span>
                          </p>
                        </div>
                      </li>
                      <li className="author-offline has-new-message">
                        <div className="user-avater">
                          <img src={Team_1} alt="" />
                        </div>
                        <div className="user-message">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              Web Design
                            </a>
                            <span className="time-posted">3 hrs ago</span>
                          </p>
                          <p>
                            <span
                              className="desc text-truncate"
                              style={{ maxWidth: 215 }}
                            >
                              Lorem ipsum dolor amet cosec Lorem ipsum
                            </span>
                            <span className="msg-count badge-circle badge-success badge-sm">
                              1
                            </span>
                          </p>
                        </div>
                      </li>
                      <li className="author-online has-new-message">
                        <div className="user-avater">
                          <img src={Team_1} alt="" />
                        </div>
                        <div className="user-message">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              Web Design
                            </a>
                            <span className="time-posted">3 hrs ago</span>
                          </p>
                          <p>
                            <span
                              className="desc text-truncate"
                              style={{ maxWidth: 215 }}
                            >
                              Lorem ipsum dolor amet cosec Lorem ipsum
                            </span>
                            <span className="msg-count badge-circle badge-success badge-sm">
                              1
                            </span>
                          </p>
                        </div>
                      </li>
                      <li className="author-offline">
                        <div className="user-avater">
                          <img src={Team_1} alt="" />
                        </div>
                        <div className="user-message">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              Web Design
                            </a>
                            <span className="time-posted">3 hrs ago</span>
                          </p>
                          <p>
                            <span
                              className="desc text-truncate"
                              style={{ maxWidth: 215 }}
                            >
                              Lorem ipsum dolor amet cosec Lorem ipsum
                            </span>
                          </p>
                        </div>
                      </li>
                      <li className="author-offline">
                        <div className="user-avater">
                          <img src={Team_1} alt="" />
                        </div>
                        <div className="user-message">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              Web Design
                            </a>
                            <span className="time-posted">3 hrs ago</span>
                          </p>
                          <p>
                            <span
                              className="desc text-truncate"
                              style={{ maxWidth: 215 }}
                            >
                              Lorem ipsum dolor amet cosec Lorem ipsum
                            </span>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <a href="" className="dropdown-wrapper__more">
                      See All Message
                    </a>
                  </div>
                </div>
              </li>
              <li className="nav-notification">
                <div className="dropdown-custom">
                  <a href="javascript:;" className="nav-item-toggle">
                    <span data-feather="bell"></span>
                  </a>
                  <div className="dropdown-wrapper">
                    <h2 className="dropdown-wrapper__title">
                      Notifications{" "}
                      <span className="badge-circle badge-warning ml-1">4</span>
                    </h2>
                    <ul>
                      <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                        <div className="nav-notification__type nav-notification__type--primary">
                          <span data-feather="inbox"></span>
                        </div>
                        <div className="nav-notification__details">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              James
                            </a>
                            <span>sent you a message</span>
                          </p>
                          <p>
                            <span className="time-posted">5 hours ago</span>
                          </p>
                        </div>
                      </li>
                      <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                        <div className="nav-notification__type nav-notification__type--secondary">
                          <span data-feather="upload"></span>
                        </div>
                        <div className="nav-notification__details">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              James
                            </a>
                            <span>sent you a message</span>
                          </p>
                          <p>
                            <span className="time-posted">5 hours ago</span>
                          </p>
                        </div>
                      </li>
                      <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                        <div className="nav-notification__type nav-notification__type--success">
                          <span data-feather="log-in"></span>
                        </div>
                        <div className="nav-notification__details">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              James
                            </a>
                            <span>sent you a message</span>
                          </p>
                          <p>
                            <span className="time-posted">5 hours ago</span>
                          </p>
                        </div>
                      </li>
                      <li className="nav-notification__single nav-notification__single d-flex flex-wrap">
                        <div className="nav-notification__type nav-notification__type--info">
                          <span data-feather="at-sign"></span>
                        </div>
                        <div className="nav-notification__details">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              James
                            </a>
                            <span>sent you a message</span>
                          </p>
                          <p>
                            <span className="time-posted">5 hours ago</span>
                          </p>
                        </div>
                      </li>
                      <li className="nav-notification__single nav-notification__single d-flex flex-wrap">
                        <div className="nav-notification__type nav-notification__type--danger">
                          <span data-feather="heart"></span>
                        </div>
                        <div className="nav-notification__details">
                          <p>
                            <a
                              href=""
                              className="subject stretched-link text-truncate"
                              style={{ maxWidth: 180 }}
                            >
                              James
                            </a>
                            <span>sent you a message</span>
                          </p>
                          <p>
                            <span className="time-posted">5 hours ago</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <a href="" className="dropdown-wrapper__more">
                      See all incoming activity
                    </a>
                  </div>
                </div>
              </li> */}
              <li className="nav-author">
                <div className="dropdown-custom">
                  <a href="javascript:;" className="nav-item-toggle">
                    <img src={Auth} alt="" className="rounded-circle" />
                  </a>
                  <div className="dropdown-wrapper">
                    <div className="nav-author__info">
                      <div className="author-img">
                        <img src={Auth} alt="" className="rounded-circle" />
                      </div>
                      <div>
                        <h6>Abdullah Bin Talha</h6>
                        <span>UI Designer</span>
                      </div>
                    </div>
                    <div className="nav-author__options">
                      <ul>
                        <li>
                          <Link to="/profile">
                            <span data-feather="user"></span> Profile
                          </Link>
                        </li>
                        <li>
                          <a href="changePassword">
                            <span data-feather="bell"></span> Change Password
                          </a>
                        </li>
                      </ul>
                      <a href="login" className="nav-author__signout">
                        <span data-feather="log-out"></span> Sign Out
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="navbar-right__mobileAction d-md-none">
              <a href="#" className="btn-search">
                <span data-feather="search"></span>
                <span data-feather="x"></span>
              </a>
              <a href="#" className="btn-author-action">
                <span data-feather="more-vertical"></span>
              </a>
            </div>
          </div>
        </nav>
      </header>

      <main className="main-content">
        <aside className="sidebar-wrapper">
          <div className="sidebar sidebar-collapse" id="sidebar">
            <div className="sidebar__menu-group">
              <ul className="sidebar_nav">
                <li className="menu-title">
                  <span>Main menu</span>
                </li>
                <li className="has-child open">
                  <ul>
                    <li>
                      <a className="" href="category">
                        <CategoryIcon />Category
                      </a>
                    </li>
                    <li>
                      <a className="" href="product">
                        <Inventory2Icon />Product
                      </a>
                    </li>
                    <li>
                      <a className="" href="report">
                        <AssessmentIcon />Reports
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </aside>

        <div className="contents">{props.props}</div>
        <footer className="footer-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="footer-copyright">
                  <p>
                    2020 @<a href="#">Aazztech</a>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-menu text-right">
                  <ul>
                    <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Team</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
      <div id="overlayer">
        <span className="loader-overlay">
          <div className="atbd-spin-dots spin-lg">
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
          </div>
        </span>
      </div>
      <div className="overlay-dark-sidebar"></div>
    </>
  );
};

export default Sidebaar;
