import { Formik } from "formik";
import * as Yup from "yup";

const CategorySchema = Yup.object().shape({
  category_name: Yup.string()
    .required("Please Enter Category Name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  image: Yup.string()
    .required("Please Enter Image")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
});
const AddCategory = () => {
  return (
    <>
      {/* <div className="contents"> */}
      {/* <div className="container-fluid"> */}
      <div className="row">
        {/* <div className="col-lg-12">
          <div className="shop-breadcrumb">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Add Category</h4>
            </div>
          </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="global-shadow border px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-10">
                    <div className="mx-sm-30 mx-20 ">
                      <div className="card add-product p-sm-30 p-20 mb-30">
                        <div className="card-body p-0">
                          <div className="card-header">
                            <h6 className="fw-500">Add Category</h6>
                          </div>
                          <div className="add-product__body px-sm-40 px-20">
                            <Formik
                              initialValues={{
                                category_name: "",
                                image: "",
                              }}
                              validationSchema={CategorySchema}
                              onSubmit={(values) => {
                                // dispatch(getAuth(values));
                                console.log(values);
                              }}
                            >
                              {({
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                              }) => (
                                <form>
                                  <div className="form-group">
                                    <label htmlFor="name1">Category Name</label>
                                    <input
                                      name="category_name"
                                      type="text"
                                      className="form-control"
                                      id="name1"
                                      placeholder="Enter Category Name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.category_name}
                                    />
                                    {errors.category_name &&
                                    touched.category_name ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {errors.category_name}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="name2">
                                      Category Image
                                    </label>
                                    <input
                                      name="image"
                                      type="file"
                                      className="form-control"
                                      id="name2"
                                      placeholder="Enter Image"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.image}
                                    />
                                    {errors.image && touched.image ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {errors.image}
                                      </div>
                                    ) : null}
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>

                      <div className="button-group add-product-btn d-flex justify-content-end mt-40">
                        <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                          cancel
                        </button>
                        <button className="btn btn-primary btn-default btn-squared text-capitalize">
                          Save Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
