import React from "react";
import { Link } from "react-router-dom";
import progress3 from "../../styles/img/svg/progress3.svg";
import { Formik } from "formik";
import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  address: Yup.string().required("Please enter your address"),
  city: Yup.string().required("Please enter your city"),
  state: Yup.string().required("Please enter your state"),
  zipCode: Yup.string()
    .required("Please Enter Your Zipcode")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});
const ThirdPage = () => {
  return (
    <div class="container-fluid">
      <div class=" checkout wizard8 global-shadow border px-sm-50 px-20 mb-30 bg-white radius-xl w-100">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <div class="row justify-content-center">
              <div class="col-xl-5">
                <div class="checkout-progress-indicator">
                  <div class="checkout-progress justify-content-center px-0">
                    <div class="step completed" id="1">
                      <span class="las la-check"></span>
                    </div>
                    <div class="step completed" id="2">
                      <span class="las la-check"></span>
                    </div>
                    <div class="step current" id="3">
                      <span>3</span>
                    </div>
                  </div>
                  <div class="card-header border-bottom-0">
                    <h4>Please fill in your address</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the dummy typesetting
                      industry.
                    </p>
                    <img class="svg" src={progress3} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-7 col-lg-8 col-sm-10">
                <div class="card checkout-shipping-form">
                  <div class="card-header border-bottom-0 pb-sm-0 pb-1 px-0">
                    <h4 class="fw-500">2. Please fill in your address</h4>
                  </div>
                  <div class="card-body p-0">
                    <div class="edit-profile__body">
                      <Formik
                        initialValues={{
                          address: "",
                          city: "",
                          state: "",
                          zipCode: "",
                        }}
                        validationSchema={SignUpSchema}
                        onSubmit={(values) => {
                          // dispatch(getAuth(values));
                          console.log(values);
                        }}
                      >
                        {({
                          values,
                          touched,
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <>
                            <form>
                              <div class="form-group">
                                <label for="name32">Address</label>
                                <input
                                  name="address"
                                  type="text"
                                  class="form-control mb-15"
                                  id="name32"
                                  placeholder="Enter Address"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.address}
                                />
                                {errors.address && touched.address ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.address}
                                  </div>
                                ) : null}
                              </div>
                              <div class="form-group">
                                <label for="city">City</label>
                                <input
                                  name="city"
                                  type="text"
                                  class="form-control"
                                  id="city"
                                  placeholder="Enter City"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.city}
                                />
                                {errors.city && touched.city ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.city}
                                  </div>
                                ) : null}
                              </div>

                              <div class="form-group">
                                <label for="state">State</label>
                                <input
                                  name="state"
                                  type="text"
                                  class="form-control"
                                  id="state"
                                  placeholder="Enter State"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.state}
                                />
                                {errors.state && touched.state ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.state}
                                  </div>
                                ) : null}
                              </div>
                              <div class="form-group">
                                <label for="zipcode">ZipCode</label>
                                <input
                                  name="zipCode"
                                  type="text"
                                  class="form-control mb-15"
                                  id="zipcode"
                                  placeholder="Enter ZipCode"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.zipCode}
                                />
                                {errors.zipCode && touched.zipCode ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {errors.zipCode}
                                  </div>
                                ) : null}
                              </div>

                              <div class="button-group d-flex pt-3 mb-20 justify-content-between flex-wrap">
                                <Link
                                  to="/register2"
                                  class="btn btn-light btn-default btn-squared fw-400 text-capitalize m-1"
                                >
                                  <i class="las la-arrow-left mr-10"></i>
                                  Previous
                                </Link>
                                <Link
                                  to="/login"
                                  class="btn text-white btn-primary btn-default btn-squared text-capitalize m-1"
                                >
                                  Save
                                  <i class="ml-10 mr-0 las la-arrow-right"></i>
                                </Link>
                              </div>
                            </form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdPage;
